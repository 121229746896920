import React from "react";
import { Link } from "react-router-dom";
import "./svg.css";

import { useStyles } from "./HeaderStyle";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

function HeaderWeb() {
  const classes = useStyles();

  const openInNewTabAndroid = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.dlamon1.tk421dev"
    );
  };

  const openInNewTabClockotron = () => {
    window.open("https://github.com/dlamon1/clockotron");
  };

  return (
    <>
      {/* LEFT SIDE */}
      {/* LEFT SIDE */}
      {/* LEFT SIDE */}
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ backgroundColor: "", height: "100%", width: "100%" }}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: "" }}
          >
            <Box
              component={Link}
              to="/"
              style={{
                maxHeight: 56,
              }}
            >
              <img
                src="/images/SVG/LeadLED_Brandmark_White-min.svg"
                alt="LED Wall"
                style={{ height: 45, color: "orange" }}
              />
            </Box>
            <Typography
              component={Link}
              to="/"
              color="secondary"
              variant="h6"
              style={{
                marginLeft: "15px",
                fontSize: 22,
                backgroundColor: "",
              }}
              className={classes.logo}
            >
              LEAD LED
            </Typography>
          </Grid>
        </Grid>

        {/* RIGHT SIDE */}
        {/* RIGHT SIDE */}
        {/* RIGHT SIDE */}
        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: "" }}
          >
            <Typography
              onClick={openInNewTabAndroid}
              color="secondary"
              variant="h6"
              align="right"
              className={classes.appBarButtons}
            >
              Android
            </Typography>
            <Typography
              onClick={openInNewTabClockotron}
              color="secondary"
              variant="h6"
              align="right"
              className={classes.appBarButtons}
            >
              iOS
            </Typography>
            <Typography
              component={Link}
              to="/userOnMade/home/projects"
              color="secondary"
              variant="h6"
              align="right"
              className={classes.appBarButtons}
            >
              TK-421
            </Typography>

            <Divider
              orientation="vertical"
              flexItem
              className={classes.divider}
            />
            <Typography
              color="secondary"
              variant="h6"
              align="right"
              className={classes.appBarButtons}
              component={Link}
              to="/userOnMade/home/projects"
            >
              Log in
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default HeaderWeb;
