import { useState, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { SketchPicker } from "react-color";
import { useFormik } from "formik";
import * as Yup from "yup";

import { StoreContext } from "../../../../stores/store.context";

import { defaultColors } from "../../../../utils/Colors";

import { updateOwnerScreen } from "../../../../graphql/mutations";

import useStyles from "./EditSideBarStyle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const ScreenEdit = (props) => {
  const { setEditRequested } = props;
  const { project, user } = useContext(StoreContext);
  const screen = project.selectedScreenEdit

  const c = useStyles();
  const panels = user.panelTypes;
  const panel = screen.panel ? screen.panel : screen.userPanel;

  const [isRequested, setIsRequested] = useState(false);
  const [colorModalOpen, setColorModalOpen] = useState(false);
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const [_color, setColor] = useState(screen.color);
  const [make, setMake] = useState(panel ? panel.make.toLowerCase() : "");
  const [modelId, setModelId] = useState(screen.panel.id);

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    panelColumns: Yup.number().min(0).required("Required"),
    panelRows: Yup.number().min(0).required("Required"),
    startX: Yup.number().min(0).required("Required"),
    startY: Yup.number().min(0).required("Required"),
    rotation: Yup.number().min(0).required("Required"),
    color: Yup.string().required("Required"),
    make: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: screen.name,
      panelColumns: screen.panelColumns,
      panelRows: screen.panelRows,
      startX: screen.startX,
      startY: screen.startY,
      rotation: screen.rotation,
      color: screen.color,
      make: panel.make.toLowerCase(),
    },
    validationSchema,
  });

  const submit = async () => {
    await updateScreen();
    setEditRequested();
  };

  const cancel = () => {
    setEditRequested();
  };

  const panelMakeOptions = [
    ...new Set(
      panels.map((panel) => {
        return panel.make.toLowerCase();
      })
    ),
  ];

  const sortedPanelMakeOptions = panelMakeOptions;

  const createModelList = (make) => {
    const models = panels
      .filter((panel) => panel.make.toLowerCase() === make)
      .sort();

    const sortedModels = models.sort((a, b) => a.model.localeCompare(b.model));

    const list = [];

    sortedModels.forEach((m) => list.push(m.model));
    return list;
  };

  let initList = createModelList(panel.make.toLowerCase());

  const [sortedModelNames, setSortedModelNames] = useState(initList);
  const [selectedModel, setSelectedModel] = useState(screen.panel.model);

  const selectMake = (value) => {
    setMake(value);
    let list = createModelList(value);
    setSortedModelNames(list);
    setSelectedModel("");
  };

  const selectModelHandleChange = (model) => {
    setSelectedModel(model);
    let i = panels.filter((m) => m.model === model);
    setModelId(i[0].id);
  };

  const openColor = (x) => {
    setPos({
      x: x.clientX - 150,
      y: x.clientY,
    });
    setColorModalOpen(true);
  };

  const closeColor = () => {
    setColorModalOpen(false);
  };

  const changeColor = (color, event) => {
    setColor(color.hex);
  };

  const updateScreen = async () => {
    setIsRequested(true);

    let p = panels.filter((p) => p.id === modelId);

    try {
      const newScreen = {
        id: screen.id,
        name: formik.values.name,
        panelColumns: parseInt(formik.values.panelColumns),
        panelRows: parseInt(formik.values.panelRows),
        startX: parseInt(formik.values.startX),
        startY: parseInt(formik.values.startY),
        rotation: parseInt(formik.values.rotation),
        color: _color,
        // ownerScreenPanelId: !p.isUserPanel ? modelId : null,
        // ownerScreenUserPanelId: p.isUserPanel ? modelId : null,
        // isUsingUserPanel: p.isUserPanel,
      };

      let resp = await API.graphql(
        graphqlOperation(updateOwnerScreen, { input: newScreen })
      );
    } catch (err) {
      console.error(err);
    }
    setIsRequested(false);

    return;
  };

  return (
    <>
      {/* Screen */}
      {/* Screen */}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: 10, width: "100%", background: "" }}
      >
        <Typography
          style={{
            backgroundColor: screen.color,
            marginLeft: 15,
            marginRight: 15,
            width: "80%",
            textAlign: "center",
          }}
        >
          {screen.name}
        </Typography>
        <Grid item style={{ marginTop: 10, width: "95%" }}>
          <TextField
            id="name"
            name="name"
            label="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={"name" in formik.touched && "name" in formik.errors}
            variant="outlined"
            size="small"
            fullWidth={true}
          />
        </Grid>
        {/* <Grid item style={{ marginTop: 0, width: "100%" }}>
          <FormControl
            style={{ width: "95%", marginLeft: "2.5%" }}
            variant="outlined"
            margin="dense"
          >
            <InputLabel id="make-label">Panel Make</InputLabel>
            <Select
              id="make"
              name="make"
              labelId="make-label"
              label="Panel Make"
              value={make}
              onChange={(e) => selectMake(e.target.value)}
            // margin="dense"
            >
              {sortedPanelMakeOptions.map((make, index) => (
                <MenuItem key={index} value={make}>
                  {make}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}
        {/* <Grid item style={{ marginTop: 0, width: "100%" }}>
          <FormControl
            style={{ width: "95%", marginLeft: "2.5%" }}
            variant="outlined"
            margin="dense"
          >
            <InputLabel id="model-label">Panel Model</InputLabel>
            <Select
              id="model"
              labelId="model-label"
              label="Panel Model"
              value={selectedModel}
              onChange={(e) => selectModelHandleChange(e.target.value)}
              margin="dense"
            >
              {sortedModelNames.map((model, i) => (
                <MenuItem key={i} value={model}>
                  {model}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item style={{ marginTop: 10, width: "95%" }}>
          <TextField
            id="panelColumns"
            name="panelColumns"
            label="Columns"
            value={formik.values.panelColumns}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              "panelColumns" in formik.touched &&
              "panelColumns" in formik.errors
            }
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item style={{ marginTop: 10, width: "95%" }}>
          <TextField
            id="panelRows"
            name="panelRows"
            label="Rows"
            value={formik.values.panelRows}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              "panelRows" in formik.touched && "panelRows" in formik.errors
            }
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item style={{ marginTop: 10, width: "95%" }}>
          <TextField
            id="startx"
            name="startx"
            label="Start X"
            value={formik.values.startX}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={"startx" in formik.touched && "startx" in formik.errors}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item style={{ marginTop: 10, width: "95%" }}>
          <TextField
            id="startY"
            name="startY"
            label="Start Y"
            value={formik.values.startY}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={"startY" in formik.touched && "startY" in formik.errors}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item style={{ marginTop: 10, width: "95%" }}>
          <TextField
            id="rotation"
            name="rotation"
            label="Rotation"
            value={formik.values.rotation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={"rotation" in formik.touched && "rotation" in formik.errors}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item className={c.textField} style={{ width: "100%" }}>
          <Button
            type="button"
            onClick={(e) => openColor(e)}
            style={{
              backgroundColor: _color,
              width: "95%",
              marginTop: 0,
              marginLeft: "2.4%",
            }}
          >
            Color
          </Button>
        </Grid>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={colorModalOpen}
          onClose={closeColor}
          closeAfterTransition
          style={{ left: pos.x, top: pos.y - 250 }}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <SketchPicker
            color={_color}
            onChange={changeColor}
            presetColors={defaultColors}
          />
        </Modal>
      </Grid>

      {/* Submit Cancel Buttons */}
      {/* Submit Cancel Buttons */}
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        align="center"
      >
        <Button
          onClick={submit}
          style={{ marginTop: 10, margin: 15 }}
          variant="contained"
          disabled={isRequested}
        >
          {isRequested ? (
            <CircularProgress
              size={21}
              style={{ marginLeft: 20, marginRight: 20 }}
            />
          ) : (
            <Typography>Submit</Typography>
          )}
        </Button>
        <Button
          onClick={cancel}
          style={{ margin: 15, marginTop: -5 }}
          className={c.editButtons}
          variant="contained"
        >
          Cancel
        </Button>
      </Grid>
    </>
  );
};

export default ScreenEdit;
